<template>
    <div class="page">
        <div class="intro">
            <h1>FLYt Case Study</h1>
        </div>
        <div class="content">
            <ContentBlock bgColor="#EFEFEF">
                    <img id="logo" :src="require(`@/assets/flyt.png`)" />
                    <img :src="require(`@/assets/flyt.gif`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Challenge</h3>
                    <h4>Understanding the Problem</h4>
                    <p>2020 was a year of significant change for the entire world. Employees immediately adapted to working from home while students adjusted to schooling online. Workplace collaboration and communication shifted overnight and a high functioning platform was needed to handle the extreme work load. With the current state of the world, more people continue to transition away from the office and need a reliable way to communicate and collaborate in the working world.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Process</h3>
                    <h4>Survey Results</h4>
                    <p>In order to discover the features and functions users would want, I surveyed 30 people.<br/><br/>[Ages 25-34 (30%), 35-44 (40%), and 65+ (20%), 65% female, 35% male.]<br/><br/>The results demonstrated that cloud storage is beneficial in all areas of life, both work and personal. The majority of users collaborate on projects, inferring that real time sharing and collaboration is a must have feature to set FLYt above the competition.</p>
                    <div class="contentBlock-flex">
                        <div>
                            <p>Do you collaborate with colleagues on projects?</p>
                            <img :src="require(`@/assets/cloudsurvey1.png`)" />
                        </div>
                        <div>
                            <p>Do you use cloud storage for business or personal?</p>
                            <img :src="require(`@/assets/cloudsurvey2.png`)" />
                        </div>
                    </div>
                    <h4>Competitive Analysis</h4>
                    <p>While competitors have made headway in cloud storage in recent years, there is still massive room for improvement in the dashboard layout, online learning, and real time collaboration among work colleagues.</p>
                     <div class="contentBlock-flex">
                        <div>
                            <h5>Google Drive</h5>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>Google Drive is the leading competitor with high standards for user security, easily accessible content from any device, enhanced real time collaboration, and elevated platform for students using the features of Google Classroom.</span><br/><br/><span class="strengthWeaknessText">Weaknesses<br/>While Google Drive has gained major ground in cloud storage, there are some drawbacks. There are difficulties editing with too many users, a need to enhance plug in capabilities, and still the threat of user data loss and theft.</span></p>
                        </div>
                        <div>
                            <h5>Dropbox</h5>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>Dropbox is another leading competitor that advanced early on. They control access privileges to share information securely and use a simple link to share a document with other users.</span><br/><br/><span class="strengthWeaknessText">Weaknesses<br/>Dropbox continues to have major areas of improvement to remain a leader in the market. Files cannot be edited synchronously, the packages are priced highly, and there is still a lack of visibility at business organizations.</span></p>
                        </div>
                    </div>
                    <h4>User Personas</h4>
                    <p>My vision for the product couldn’t become a reality until digging deeper into the intricacies of the users and their actual experience working with the tools of the site. I focused on two types of professionals who need reliable ways to communicate with their teams: Melissa, a Project Manager, and Craig, a National Sales Manager.<br/><br/>Both users manage their own teams in the workplace and strive to be a step ahead of the rest by developing unprecedented standards for their team which will eventually set entirely new standards for the business. Both users are valued for their ingenuity and resourcefulness, making them ideal candidates to launch a new cloud platform.</p>
                    <img :src="require(`@/assets/flytpersona1.jpg`)" />
                    <img :src="require(`@/assets/flytpersona2.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h4>User Stories</h4>
                    <p>I realized not all users would need features like sharing items and real time collaboration,many just want the basics of creating documents, spreadsheets, and presentations. I researched what new or returning users would actually want and divided these tasks into low, medium, and high priorities to match the needs of each user.</p>
                    <img :src="require(`@/assets/flytuserstories.jpg`)" />
                    <h4>User Flows</h4>
                    <p>The Flyt application has many easy to use features directly from the dashboard. Users can upgrade FLYT storage to Violet or Ultra Violet packages and upload files such as documents, photos, and videos. For school and office work, users can create and save useful documents. FLYt Ink for writing documents, FLYT Grid for spreadsheets, FLYt Demo for presentations, and FLYt Capture for photos and videos. Users can then share these documents with other users to engage in real time collaboration.</p>
                    <img :src="require(`@/assets/flytuserflow.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Framework</h3>
                    <h4>Low Fi Mockups</h4>
                    <p>Once creating the wireframes and doing user tests, I observed where users were getting stuck and what enhancements were needed for the real prototype.<br/><br/>I simplified the content from the design of the original landing page so users could easily view the features FLYt offered and choose to learn more. The dashboard was visually laid out so users could easily scan and find content. In the end, the result was clean and well organized to enhance the user experience.</p>
                    <img :src="require(`@/assets/flyt-wireframes.jpg`)" />
                    <h4>Hi Fi Mockups</h4>
                    <p>The design changed significantly from the original. I wanted to include more features on the page so the user had a better idea of the product while rearranging the hierarchy of content on the page.<br/><br/>The final design looked sharper and more professional, with added content and evident call to action.</p>
                    <img :src="require(`@/assets/flytlandingpage.jpg`)" />
                    <h4>Prototype</h4>
                    <p>The dashboard design was inspired by areas the competitors lacked and what users ultimately wanted. By making documents more visual and including more of them on the page, I made it easy for the user to quickly find what they were searching for.</p>
                    <img :src="require(`@/assets/flyt-p.gif`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Impact</h3>
                    <p>Once I had the initial prototype of the landing page and dashboard developed, I needed to test it with actual users to get realistic feedback.<br/><br/>Users suggested adding icons to the documents so it was evident what type of document they were looking at. They wanted to maximize the names of the packages and minimize the navigation bar on the left of the dashboard so there was more visual space to view documents.<br/><br/>Adding a share button and search bar, removing overcrowded content, and adding navigable paths in the nav bar were more suggestions that strengthened the design. User feedback drastically impacted the look and feel of the site, adding many upgraded options that I never would’ve considered.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Reflection</h3>
                    <h4>Discovering a Solution</h4>
                    <p>My goal with creating a cloud storage platform was to find a way to discover what users wanted to elevate the experience so they can work efficiently and effortlessly, to get the job done. I wanted the user platforms to be connected, without exiting out of one platform to get to another.<br/><br/>Features that will set FLYt above the competition:</p>
                    <ul>
                        <li>allowing the user to work offline and temporarily save content until back online</li>
                        <li>getting more free storage space. FLYt will start users out with 20 GB</li>
                        <li>accessing stored information in any format, on any device</li>
                        <li>improving the password retrieval process, so users can quickly access information</li>  
                        <li>increasing security so users trust their information will be safe</li>
                    </ul>
                    <h4>What I Learned</h4>
                     <div class="contentBlock-flex-ending">
                        <div>
                            <img :src="require(`@/assets/flytbirdlogo.png`)" />
                        </div>
                        <div>
                            <p>After completing the design, I learned that the world is constantly changing and a lot of ideas are new and haven’t been done before, simply because they haven’t been needed before.<br/><br/>Users already had a good idea of what they wanted, it was just a matter of blending their ideas together and putting them into effect.<br/><br/>I realized in many instances, I didn’t have to recreate the wheel, many sites were already implementing effective methods. It might just be a matter of tweaking those features to amplify what already exists.<br/><br/>In the end, FLYt not only met my initial expectations, but gave a unique and creative brand with enticing visual design. I focused on fixing the customer’s pain points with the competitors and I am confident this could become a successful cloud platform.</p>
                        </div>
                    </div>
            </ContentBlock>
        </div>
    </div>
</template>

<script>
    import ContentBlock from '../components/ContentBlock.vue'

    export default {
        name: 'Flyt',
        components: {
            ContentBlock
        }
    }
</script>

<style scoped>
    .intro{
        text-align: center;
        font-weight: 700;
        margin-top: 50px;
        padding: 0px 30px;
        box-sizing: border-box;
    }

     h1{
        font-size: 3em;
    }

    h2{
        font-size: 2em;
        margin-top: 10px;
    }

    h3, h5{
        color: #7600FF;
    }

    .content{
        margin: 100px 0px 30px 0px;
    }

    #logo{
        max-width: 500px;
    }

    .project-text{
        padding: 50px;
    }

    @media only screen and (max-width: 700px){
            h1{
                font-size: 2em;
            }

            h2{
                font-size: 1.3em;
                margin-top: 5px;
            }

            .project-text{
                padding: 30px;
            }
    }
</style>
