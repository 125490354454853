<template>
    <div :style="{'background-color': bgColor}">
        <div class="contentBlock">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContentBlock',
        props:{
            bgColor: {
                type: String,
                default: '#FFFFFF' // Replace 'defaultColor' with your desired default color
            }      
        },
    }
</script>

<style scoped>
    .contentBlock{
        display: block;
        margin: 30px;
        max-width: 1000px;
        margin: auto;
    }
    .contentBlock img{
        padding: 30px 0px;
        width: 100%;
        max-width: 950px;
        display: block;
        margin: auto;
    }

    .contentBlock-flex img{
        padding: 30px;
        max-width: 450px;
    }
    .contentBlock h1{
        text-align: center;
        font-size: 2.5em;
    }
    .contentBlock h2{
        font-size: 2em;
    }
    .contentBlock p, .contentBlock-flex ul{
        font-size: 1em;
        color: black;
        padding: 10px 30px;
        margin: 30px auto;
        line-height: 35px;
        text-align: left;
        box-sizing: border-box;
        flex-grow: 1;
    }

    .contentBlock ul{
        padding: 10px 30px;
        margin: 10px 20px;
        text-align: left;
        color: black;
        line-height: 35px;
        list-style-type: '- ';
    }

    .contentBlock-flex, .contentBlock-flex-ending{
        display: flex;
        flex-direction: row;
        padding: 30px 0px;
        box-sizing: border-box;
        justify-content: center;
    }

    .contentBlock-flex-ending{
        align-items: center;
    }

    .contentBlock-flex-ending > div:nth-of-type(1){
        flex: 1;
    }
    .contentBlock-flex-ending > div:nth-of-type(2){
        flex: 3;
    }

    .contentBlock-flex div{
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    h3{
        font-size: 2em;
        margin-top: 50px;
    }

    h4{
        font-size: 1.5em;
        margin-top: 80px;
    }

    h5{
        font-size: 1.2em;
        margin-top: 80px;
    }

    .strengthWeaknessText{
        flex: 1;
    }

    .strengthWeaknessP{
        display: flex;
        flex-direction: column;
    }

    @media only screen and (max-width: 900px){
            .contentBlock{
                display: block;
                margin: 20px;
            }

            .contentBlock p{
                font-size: 1em;
                color: black;
                padding: 10px;
                max-width: 1000px;
                margin: 30px auto;
                line-height: 35px;
                text-align: left;
                box-sizing: border-box;
            }

            .contentBlock-flex, .contentBlock-flex-ending{
                display: flex;
                flex-direction: column;
            }

            .contentBlock-flex div, .contentBlock-flex-ending div{
                width: 100%;
                flex: 1;
            }

            .contentBlock-flex img{
                padding: 30px 0px;
                max-width: none;
            }
    }
</style>