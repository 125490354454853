<template>
    <div class="page">
        <div class="intro">
            <h1>I'm Brittany Foster,</h1>
            <h2>a UX Designer based in Phoenix, Arizona</h2>
        </div>
        <div class="cards">
            <Card image="evolve.jpg" title="EVOLVE: Shop Case Study" color="#DB6939" link="shop">Clear visibility. Less rework. Rapid production.</Card>
            <Card image="flyt.jpg" title="FLYt Case Study" color="#7600FF" link="flyt">Perfecting the platform for cloud storage and organization.</Card>
            <Card image="limelite.jpg" title="LimeLite Case Study" color="#05B342" link="limelite">Highlighting skills and making connections in the job market.</Card>
            <Card image="instagramwithtext.jpg" title="Instagram Case Study" color="#EF426E" link="instagram">Enhancing social media to win back the younger generation.</Card>
        </div>
        <h4>What People Are Saying</h4>
        <div class="slides">
            <div v-for="(testimonial, index) in testimonials" :key="index" class="slide-text" :class="{ active: index === myIndex }">
                <p>"{{ testimonial.text }}"</p>
                <br>
                <i>-{{ testimonial.author }}</i>
            </div>
        </div>
        <h4>Let's Connect</h4>
        <a href="https://www.linkedin.com/in/brittanydawnfoster/" title="Brittany Foster" target="_blank"><img id="linkedin" :src="require(`@/assets/linkedin.jpg`)" /></a>
    </div>
</template>

<script>
    import Card from '../components/Card.vue'

    export default {
        name: 'Home',
        data() {
            return {
            myIndex: 0,
            testimonials: [
                {
                    text: "Working with Brittany has been an absolute pleasure. She is a fantastic collaborator and easily dives right into new projects. I’ve been impressed with her ability to assimilate new concepts and crank out iterative designs while under pressure and strict deadlines. Brittany is reliable, adaptable and focused - a great addition to any product team.",
                    author: "Alex Norris, UX Designer at EVOLVE",
                },
                {
                    text: "Working with Brittany has made the collaboration process smooth and fun with the product team. We worked side by side on a daily basis. I was impressed by her ability with user interface design and wireframing skills. She brought creative and thoughtful ideas to the team. Overall, Brittany is an extremely empathetic designer and will definitely be a valuable asset to any team.",
                    author: "Pei Jung Lin, UX Designer",
                },
                {
                    text: "Brittany’s color schemes are bold, yet balanced. She creates a space that catches the end user’s attention, then invites them to stay awhile and explore. I was impressed at how she managed to nail every client request. It makes my job so much easier when my designers are clear with what they want. I would 100% work with her again!",
                    author: "Adam Elliot, Front End Developer",
                },
                {
                    text: "Brittany is innovative and creative, always finding new ways to engage the end user. She brings enthusiasm to any environment she is in which is one of the attributes her colleagues love about her. She has a great deal of integrity, is dependable, and takes initiative. She challenges herself and constantly finds new ways to grow.",
                    author: "Heather Peters, Math Professor at University of Advancing Technology",
                },
                {
                    text: "I am honored to endorse Brittany for her UX work. Brittany has a keen eye for detail, a creative mindset, and ensures project deadlines are met. She has a strong work ethic and brings an open mind and positivity to any new challenge.",
                    author: "Jamie Starner, National Director of Recruiting and Diversity",
                },
                {
                    text: "Brittany has designed and implemented training programs for call center agents at our sales sites worldwide. Her thorough attention to detail and ability to manage programs from start to finish were instrumental in providing solution oriented consultation to our customers. Brittany devotes herself to her work and delivers the results that exceed expectations.",
                    author: "Anthony Fewell, National Sales Manager",
                },
                // Add more testimonials as needed
            ],
            };
        },
        components: {
            Card
        },
        methods: {
            startCarousel() {
                setInterval(() => {
                    this.myIndex = (this.myIndex + 1) % this.testimonials.length;
                }, 15000);
            },
        },
        mounted() {
            this.startCarousel();
        },
    }
</script>

<style scoped>
    .intro{
        text-align: center;
        font-weight: 700;
        margin-top: 50px;
        padding: 0px 30px;
        box-sizing: border-box;
    }

    h1{
        font-size: 3em;
    }

    h2{
        font-size: 2em;
        margin-top: 10px;
    }

    h4{
        font-size: 1.7em;
        margin-top: 50px;
    }

    .cards{
        margin: 100px 30px;
    }

    .slides{
        margin: 30px auto;
        max-width: 1000px;
        line-height: 30px;
        text-align: left;
        transition: 0.5s;
        padding: 0px 50px;
        box-sizing: border-box;
    }

    .slide-text{
        transition: 0.5s;
        height: auto;
        min-height: 250px;
        position:relative;
        animation:animateleft 0.4s;
    }

    #linkedin{
        padding: 30px;
        box-sizing: border-box;
    }

    #linkedin:hover{
        cursor: pointer;
    }

    /* Styles for the carousel container */
    .slides {
        display: flex; /* Flex layout for horizontal alignment of slides */
        flex-direction: column; /* Display slides vertically */
    }

    /* Styles for individual slides */
    .slide-text {
        display: none; /* Initially hide all slides */
    }

    /* Style for the active slide */
    .slide-text.active {
        display: block; /* Show the active slide */
    }

    @keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}

    @media only screen and (max-width: 700px){
            h1{
                font-size: 2em;
            }

            h2{
                font-size: 1.3em;
                margin-top: 5px;
            }

            .slide-text{
                height: auto;
                min-height: 350px;
            }
    }
</style>
