<template>
  <div class="menu">
     <router-link to="/"><img id="logo" src="../assets/logo.png"  title="Brittany Foster" ></router-link>
    <nav>
      <router-link to="about" class="menuItem">About</router-link>
      <router-link to="resume" class="menuItem">Resume</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Menu'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .menu{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    #logo{
      max-width: 70px;
      width:100%;
      padding: 20px;
    }

    nav{
      display: flex;
      padding: 30px;
      font-size: 1em;
    }

    nav .menuItem{
      padding: 10px;
      font-weight: 700;
      color: #595959;;
      text-decoration: none;
    }

    nav .menuItem:hover{
      color: black;
      transition: 0.5s;
      cursor: pointer;
    }
</style>
