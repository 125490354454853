<template>
    <div class="page">
        <div class="intro">
            <h1>Resume</h1>
        </div>
        <img id="resume" :src="require(`@/assets/uxresume.jpg`)" />
    </div>
</template>

<script>
    export default {
        name: 'Resume',
    }
</script>

<style scoped>
    .intro{
        text-align: center;
        font-weight: 700;
        margin-top: 50px;
        padding: 0px 30px;
        box-sizing: border-box;
    }

    h1{
        font-size: 3em;
    }

    #resume{
        padding: 30px 20px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1000px;
    }
  

    @keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}

    @media only screen and (max-width: 700px){
            h1{
                font-size: 2em;
            }
    }
</style>
