<template>
  <div id="app">
    <Menu/>
    <transition name="animate">
        <router-view/>
    </transition>
    <h6>Designed by Brittany Foster<br/>Developed by <a href="https://www.linkedin.com/in/steven-simon-29833079/" target="_blank">Steven Simon</a></h6>
  </div>
</template>

<script>
import Menu from './components/Menu.vue'

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

*{
  margin: 0;
  padding: 0;
}

body{
  background-color: #EFEFEF;
}

#app {
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #595959;
}

h6{
  padding: 10px 20px 60px 20px;
  font-size: 1em;
  line-height: 28px;
  color: #898989;
}

h6 a{
  text-decoration: none;
  color: #2380EB;
}

 h6 a:hover{
        filter: brightness(0.6);
        transition: 0.4s;
    }

 .animate-enter-active, .animate-leave-active{
    transition: opacity 0.5s ease-in-out, transform 0.5s ease;
  }

  .animate-enter-active{
    transition-delay: 0.5s;
  }

  .animate-enter, .animate-leave-to{
    opacity: 0;
  }

  .animate-enter{
    transform: translateX(-100px);
  }

  .animate-leave-to{
    transform: translateX(100px);
  }

  .animate-enter-to, .animate-leave{
    opacity: 1;
    transform: translateX(0px);
  }
</style>
