import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import Shop from "./views/Shop.vue";
import Flyt from "./views/Flyt.vue";
import LimeLite from "./views/LimeLite.vue";
import Instagram from "./views/Instagram.vue";
import About from "./views/About.vue";
import Resume from "./views/Resume.vue";

Vue.use(Router);

var pageTitle = 'Brittany Foster';
var pageName = 'description';
var pageContent = 'I am Brittany Foster, a UX Designer, and this is my portfolio.';

var myRoute = '/';

export default new Router({
  
    mode: 'hash',
    routes: [
        {
            path: myRoute,
            name: "home",
            component: Home,
            meta: {
                title: pageTitle,
                metaTags: [
                  {
                    name: pageName,
                    content: pageContent
                  }
                ]
            }
        },
        {
          path: myRoute + 'shop',
          name: "shop",
          component: Shop,
          meta: {
              title: pageTitle,
              metaTags: [
                {
                  name: pageName,
                  content: pageContent
                }
              ]
          }
        },
        {
          path: myRoute + 'flyt',
          name: "flyt",
          component: Flyt,
          meta: {
              title: pageTitle,
              metaTags: [
                {
                  name: pageName,
                  content: pageContent
                }
              ]
          }
        },
      {
        path: myRoute + 'limelite',
        name: "limelite",
        component: LimeLite,
        meta: {
            title: pageTitle,
            metaTags: [
              {
                name: pageName,
                content: pageContent
              }
            ]
        }
    },
    {
      path: myRoute + 'instagram',
      name: "instagram",
      component: Instagram,
      meta: {
          title: pageTitle,
          metaTags: [
            {
              name: pageName,
              content: pageContent
            }
          ]
      }
  },
  {
    path: myRoute + 'about',
    name: "about",
    component: About,
      meta: {
          title: pageTitle,
          metaTags: [
            {
              name: pageName,
              content: pageContent
            }
          ]
      }
    },
    {
      path: myRoute + 'resume',
      name: "resume",
      component: Resume,
      meta: {
          title: pageTitle,
          metaTags: [
            {
              name: pageName,
              content: pageContent
            }
          ]
      }
    },

  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 };
    }
}

});