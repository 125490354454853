<template>
    <div class="page">
        <div class="intro">
            <h1>LimeLite Case Study</h1>
        </div>
        <div class="content">
            <ContentBlock bgColor="#EFEFEF">
                    <img id="logo" :src="require(`@/assets/limelite.png`)" />
                    <img :src="require(`@/assets/limelite-laptop.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Challenge</h3>
                    <h4>Understanding the Problem</h4>
                    <p>Currently, applicants searching for their ideal job are at a major disadvantage. There is a huge gap between the applicant and employer, often the applicant never getting feedback whether their application was even received. A significant advancement needs to be made in the field of online employment where an applicant can make a lasting impact from the start.<br/><br/>A designer's insight is needed to discover the best way to connect with both employers and applicants.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Process</h3>
                    <h4>Survey Results</h4>
                    <p>To be able to understand both user perspectives, I surveyed 28 people.<br/><br/>[Ages 25-34 (53.6%), 35-44 (28.6%), 55-64 (3.6%) and 65+ (7.1%, 46.4% female, 53.6% male.]<br/><br/>As a result, more than 75% of applicants believed their biggest hurdle when not getting the job was being over looked by the employer. That same percentage of people would attend events in their chosen field if it meant connecting with potential employers face to face.</p>
                    <div class="contentBlock-flex">
                        <div>
                            <p>Would you attend events in your chosen field if you could connect with potential employers?</p>
                            <img :src="require(`@/assets/limelitesurvey1.png`)" />
                        </div>
                        <div>
                            <p>How often do you apply for a new job?</p>
                            <img :src="require(`@/assets/limelitesurvey2.png`)" />
                        </div>
                    </div>
                    <h4>Competitive Analysis</h4>
                    <p>Platforms like LinkedIn and Indeed have focused on closing the gap between employer and applicant, yet there are still significant ways to show an applicant’s true skills so the best person is hired for the job.</p>
                     <div class="contentBlock-flex">
                        <div>
                            <h5>LinkedIn</h5>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>LinkedIn began an unprecedented platform where people wanting to make an impact in the job market could show their skills. It facilitates effortless communication between employees, peers, businesses, and consumers.</span><br/><br/><span class="strengthWeaknessText">Weaknesses<br/>Even though LinkedIn is a leading competitor, the company still struggles with product demand and available job listings leading to a higher rate of missed opportunities. Companies who don’t use this site, might be overlooked.</span></p>
                        </div>
                        <div>
                            <h5>Indeed</h5>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>The current Indeed platform has skyrocketed in popularity throughout the past several years. Since eliminating the need to visit other job marketing sites, they continue to have the highest traffic for online job marketing, 250 million visitors every month.</span><br/><br/><span class="strengthWeaknessText">Weaknesses<br/>Indeed has a stripped down, no frills platform where the navigation and layout are not as aesthetically pleasing as other sites. It can also be redundant with competitor’s job listings.</span></p>
                        </div>
                    </div>
                    <h4>User Personas</h4>
                    <p>It was most important to understand what employers were looking for so I could focus on enhancing those qualities in the applicant. To understand what the employer’s main focus was, I narrowed in on two types of professionals: Allison, an HR recruiter, and Justin, a business owner.<br/><br/>Both users have specific qualities they are looking for in an applicant and agree that there are large gaps in traditional methods of the hiring process. Gaining ideas from both the applicant and employer will tighten the bond and connect like minded individuals.</p>
                    <img :src="require(`@/assets/limelitepersona1.jpg`)" />
                    <img :src="require(`@/assets/limelitepersona2.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h4>User Stories</h4>
                    <p>It was imperative to discover which features were most important to users and which they consider an upgrade. Sectioning these tasks into low, medium, and high made it easy to determine which features users needed most and which features they would be willing to pay more for.</p>
                    <img :src="require(`@/assets/limeliteuserstories.jpg`)" />
                    <h4>User Flows</h4>
                    <p>Once signing up for LimeLite, the user will arrive at a main dashboard leading to four main tabs: My Profile, Sharpen My Tools, Connect, and Search &amp; Apply. The simplicity allows the user to apply for job positions quickly, discover marketing events in their area, connect with recruiters instantly, and update their personal profile with recommendations, skills, and past work experience. This user flow maps out the common paths a user will take and how they can easily navigate around to enhance the user experience.</p>
                    <img :src="require(`@/assets/limeliteuserflow.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Framework</h3>
                    <h4>Low Fi Mockups</h4>
                    <p>While creating the design of the four dashboard pages, I wanted to be consistent but also make each page uniquely it's own.<br/><br/>Simplicity made visuals on the dashboard easy to navigate. As I designed the page layouts, I envisioned what the users would want to see and the importance of the hierarchy of content.</p>
                    <img :src="require(`@/assets/limelite-wireframes.jpg`)" />
                    <h4>Hi Fi Mockups</h4>
                    <p>The Hero image I chose told a story and conveyed action. It implies the action of driving to and from work - a big job in a big city. The tagline at the top left of the page is relatable and also conveys strong action: Get off the couch and into the LimeLite. The rest of the landing page followed the same theme with big signs and bright lights all with the user’s end goal in mind. The page highlights the features without going into too much depth and encourages them to ‘Get Moving’ by clicking the green call to action button.</p>
                    <img :src="require(`@/assets/limelitelandingpage.jpg`)" />
                    <h4>Prototype</h4>
                    <p>The mobile dashboard design of the Search &amp; Apply section kept the information simple and to the point so the user can easily scan for jobs that would be an ideal fit. Once they click on the call to action button, they are able to see detailed information about the job and easily apply with the resume from their profile.</p>
                    <img :src="require(`@/assets/limelite-p.png`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Impact</h3>
                    <p>Users wanted to change the design to look more clean and sophisticated without including too much on each page. I reduced the content to focus on the most important features, then the user could click further to find out more.<br/><br/>Users agreed that adding more specific features on the landing page would give a better idea of what it was they were signing up for. They wanted to know how this platform stood out from the competition and how it could enhance their chances of getting the job they really wanted.<br/><br/>Lastly, users suggested enhancing ways for the employer to search for potential candidates for open positions so they could reach out and recommend someone apply for a specific position if they meet the qualifications.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Reflection</h3>
                    <h4>Discovering a Solution</h4>
                    <p>The idea behind LimeLite started with a challenge of applicants to be seen in the working world, especially when transitioning into a new field. Most employers want to see several years of experience in the field and few are willing to give an individual with no experience a chance.<br/><br/>With this innovative platform, LimeLite users will be able to:</p>
                    <ul>
                        <li>create a resume, cover letter, or portfolio</li>
                        <li>highlight their employment skills</li>
                        <li>proactively reach out to employers</li>
                        <li>discover marketing events in their field</li>  
                        <li>make a strong impression before sitting down for an interview</li>
                    </ul>
                    <h4>What I Learned</h4>
                     <div class="contentBlock-flex-ending">
                        <div>
                            <img :src="require(`@/assets/limelitelogo.png`)" />
                        </div>
                        <div>
                            <p>For this project, I was passionate about finding new and effective methods for applicants to showcase their skills and get noticed for jobs they don’t yet have experience in. Getting noticed by any employer can be a challenge, especially when there isn’t a way to get to know that person’s personality.<br/><br/>Nepotism has good and bad points but ultimately it can make it impossible for the most skilled applicants to even be given a chance at making a career change.<br/><br/>My goal with LimeLite was to allow applicants to make connections with employers or people in their field without relying on friends and family to get their foot in the door.<br/><br/>LimeLite will allow those who are truly skilled and meant for the job to be able to stand out among the competition. Employers won’t be so quick to breeze through their resume when they can easily connect with them through chat or face to face recruiting events.</p>
                        </div>
                    </div>
            </ContentBlock>
        </div>
    </div>
</template>

<script>
    import ContentBlock from '../components/ContentBlock.vue'

    export default {
        name: 'Flyt',
        components: {
            ContentBlock
        }
    }
</script>

<style scoped>
    .intro{
        text-align: center;
        font-weight: 700;
        margin-top: 50px;
        padding: 0px 30px;
        box-sizing: border-box;
    }

     h1{
        font-size: 3em;
    }

    h2{
        font-size: 2em;
        margin-top: 10px;
    }

    h3, h5{
        color: #05B342;
    }

    .content{
        margin: 100px 0px 30px 0px;
    }

    #logo{
        max-width: 500px;
    }

    .project-text{
        padding: 50px;
    }

    @media only screen and (max-width: 700px){
            h1{
                font-size: 2em;
            }

            h2{
                font-size: 1.3em;
                margin-top: 5px;
            }

            .project-text{
                padding: 30px;
            }
    }
</style>
