<template>
    <div class="page">
        <div class="intro">
            <h1>EVOLVE: Shop Case Study</h1>
        </div>
        <div class="content">
            <ContentBlock bgColor="#EFEFEF">
                    <img id="logo" :src="require(`@/assets/evolve.png`)" />
                    <img :src="require(`@/assets/shop-workOrder.gif`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Challenge</h3>
                    <h4>Understanding the Problem</h4>
                    <p>There is a common roadblock among the construction industry, a gap connecting all of the platforms needed to complete a project. As a result, departments (such as Design, Field, and Shop) are not communicating efficiently and projects are behind schedule before they begin. A major focus of EVOLVE became understanding challenges a Shop Foreman faces as they manage large teams, scattered communications, and visibility into the Field.This product will delve into how a top performing, streamlined Shop can be run.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Process</h3>
                    <h4>Research Results</h4>
                    <p>Early on, lighthouse partners came on board with our team to collaborate about real world issues. One partner in particular helped us narrow down where to focus first.They described the biggest pain point as the inability to follow a project’s progress from start to finish. It was difficult to uncover potential problems because the current software could not perform the functionality to communicate among departments.<br><br>The following features had never been done by any construction software program:</p> 
                    <ul>
                        <li>viewing work order information from a Kanban board or calendar</li>
                        <li>canceling while in production for rework</li>
                        <li>adding attachments and comments for clarification</li>
                        <li>automatically syncing massive amounts of data and files</li>
                        <li>creating an extensive location breakdown structure in minutes</li>
                        <li>auto routing tasks to work cells</li>
                        <li>interacting with 3D modeling support</li>
                    </ul>
                    <p>The focus was on improving the Shop process because this was the largest bottleneck for any project. Once the lighthouse partners were fully able to define their roadblocks, we were able to delve into the solutions.</p>
                    <h4>Competitive Analysis</h4>
                    <p>The competition was easy to overcome, simply because there is no other product like it on the market. Companies were using basic Excel spreadsheets to store complex data.<br><br>Users wanted to be able create work requests, submit to become a work order, and follow the work order throughout the entire process.</p>
                    <h4>Excel</h4>
                    <div class="contentBlock-flex">
                        <div>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>Excel spreadsheets can store unlimited rows of data which is highly beneficial to the user. When it comes to storing data of a high magnitude, Excel still remains the leader for this industry. The data is also able to be transferred to certain platforms.</span><br><br><span class="strengthWeaknessText">Weaknesses<br/>The user has to manually create a hierarchy for the information. Visually the information is not ideal to the user and can be difficult to make sense of. It is easy to make mistakes and overlook important information.</span></p>
                        </div>
                        <div>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Opportunities<br/>Excel was not created for the construction industry so it lacks in areas a new product can cater to specific user needs. The users are doing too much manual entry which is time consuming and leads to potential mistakes.</span><br/><br/><span class="strengthWeaknessText">Threats<br/>Most companies have continued using basic spreadsheets because there isn’t a competitor in the market. With EVOLVE, the tables were designed specifically to meet the needs of the industry.</span></p>
                        </div>
                    </div>
                    <h4>User Personas</h4>
                    <p>Meeting with actual users in the workplace was eye opening when envisioning the direction of the product. We observed designers and shop workers using the tools of our site in the real world. We could pinpoint gaps in the design as well as features of additional programs they used in their role.<br><br>By communicating with a Design Manager and Shop Foreman of a successful company, we began to bridge the gap between these two departments.</p>
                    <img :src="require(`@/assets/shop-persona1.png`)" />
                    <img :src="require(`@/assets/shop-persona2.png`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h4>User Stories</h4>
                    <p>The lighthouse partners were consistent in their priorities of wants and needs which made it easier to understand where other programs were lacking. Being able to interact with the 3D assembly model was highly beneficial for design users.</p>
                    <img :src="require(`@/assets/shop-user-stories.jpg`)" />
                    <h4>User Flows</h4>
                    <p>Starting with creating a work request, this user flow demonstrates the process of a work order from start to finish.<br><br>Once a work order is created, the user can split into multiple, release to production and start the tasks in the queue. They will continue the process until the entire work order is completed.</p>
                    <img :src="require(`@/assets/shop-userflow.png`)" />
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Framework</h3>
                    <h4>Low Fi Mockups</h4>
                    <p>I made the calendar visually appealing and simple to use, so the Shop Foreman can view project dates by weeks, months or years in advance. By clicking on the date, the flyout quickly highlights important information for upcoming deadlines so the user is aware of problems far in advance.</p>
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-wireframe1.jpg`)" />
                        <img :src="require(`@/assets/shop-wireframe2.jpg`)" />
                    </div>
                    <h4>Hi Fi Mockups</h4>
                    <p>We had the calendar developed as a high priority and shipped to the customer within weeks. This specific tool was a game changer for visualizing projects at an overview and we were given immediate feedback of how they could use this tool to enhance other parts of the product.</p>
                    <img :src="require(`@/assets/shop-hiFi-mockup1.jpg`)" />
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-hiFi-mockup2.jpg`)" />
                        <img :src="require(`@/assets/shop-hiFi-mockup3.jpg`)" />
                    </div>
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-hiFi-mockup4.jpg`)" />
                        <img :src="require(`@/assets/shop-hiFi-mockup5.jpg`)" />
                    </div>
                    <img :src="require(`@/assets/shop-hiFi-mockup6.jpg`)" />
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-hiFi-mockup7.jpg`)" />
                        <img :src="require(`@/assets/shop-hiFi-mockup8.jpg`)" />
                    </div>
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-hiFi-mockup9.jpg`)" />
                        <img :src="require(`@/assets/shop-hiFi-mockup10.jpg`)" />
                    </div>
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-hiFi-mockup11.jpg`)" />
                        <img :src="require(`@/assets/shop-hiFi-mockup12.jpg`)" />
                    </div>
                    <div class="contentBlock-flex">
                        <img :src="require(`@/assets/shop-taskViewer1.png`)" />
                        <img :src="require(`@/assets/shop-taskViewer2.png`)" />
                    </div>
                    <h4>Prototype</h4>
                    <p>Building a prototype was an efficient way to communicate with developers and end users, to showcase the intention of the product before development.</p>
                    <div class="contentBlock-flex">
                        <video controls autoplay loop>
                            <source :src="require(`@/assets/shop-workOrder.mp4`)"  type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Impact</h3>
                    <p>The more features that were added to the Shop module, the more specific the customers were with their wants and needs. Filtering content, pausing and blocking work orders, and splitting work orders into multiple were at the top of list as must haves.<br><br>To enhance the experience, we added viewing work order information from a project calendar or Kanban interface, and allowing them to attach photos to communicate the issues they were experiencing.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Reflection</h3>
                    <h4>Discovering a Solution</h4>
                    <p>EVOLVE began as a startup from a larger company, Applied Software, until it was able to stand on its own in April 2022.<br><br>The company focused on a seamless user experience from start to finish. The goal was to have everything in one place and have other existing software be compatible with our program.<br><br>EVOLVE understands the challenges that a Shop Foreman faces as they bridge the gaps among all departments. The modules all sync seamlessly on one platform, which streamlines communications between Design, Shop, and Field, leading to less rework and more efficient workflows.</p>
                    <h4>What I Learned</h4>
                     <div class="contentBlock-flex-ending">
                        <div>
                            <img :src="require(`@/assets/shoplogo.png`)" />
                        </div>
                        <div>
                            <p>The product we are creating will have an immense impact on the industry as a whole. It was not built simply to compete with another, but was one that had never been attempted before. It will revolutionize the construction industry and the way companies will operate.<br><br>Once launching the product, we began to receive even more requests of features and must haves. It was obvious there was no product out there that even came close to meeting the needs of the user. Being such a prominent industry, it’s surprising that there was such a missed opportunity. But a major reason a product like this had not been attempted before was due to how difficult it would be to change the mindset of the user who were used to their usual method of production.<br><br>Connecting with users for feedback and observing them using the tools has changed the direction of the product. We are finding new pain points that would be challenging to uncover otherwise and tailoring the interfaces to fit their needs. The newest challenge is getting users to reexamine the manual method of doing the job and embracing a cutting edge process. The product still has a ways to go before it encompasses everything the user needs, but in a short amount of time it has already solved many of the major pain points of the construction industry.</p>
                        </div>
                    </div>
            </ContentBlock>
        </div>
    </div>
</template>

<script>
    import ContentBlock from '../components/ContentBlock.vue'

    export default {
        name: 'Shop',
        components: {
            ContentBlock
        }
    }
</script>

<style scoped>
    .intro{
        text-align: center;
        font-weight: 700;
        margin-top: 50px;
        padding: 0px 30px;
        box-sizing: border-box;
    }

     h1{
        font-size: 3em;
    }

    h2{
        font-size: 2em;
        margin-top: 10px;
    }

    h3, h5{
        color: #DB6939;
    }

    .content{
        margin: 100px 0px 30px 0px;
    }

    #logo{
        max-width: 500px;
    }

    .project-text{
        padding: 50px;
    }

    video{
        width: 100%;
    }

    @media only screen and (max-width: 700px){
            h1{
                font-size: 2em;
            }

            h2{
                font-size: 1.3em;
                margin-top: 5px;
            }

            .project-text{
                padding: 30px;
            }
    }
</style>
