<template>
    <div class="page">
        <div class="intro">
            <h1>Instagram Enhancement Case Study</h1>
        </div>
        <div class="content">
            <ContentBlock bgColor="#EFEFEF">
                    <img id="logo" :src="require(`@/assets/instagram.png`)" />
                    <img :src="require(`@/assets/phone.png`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Challenge</h3>
                    <h4>Understanding the Problem</h4>
                    <p>As of 2018, Instagram lost the majority of their middle school users to new competitors. To target the middle school age audience and become the leading social media site for this age group, Instagram needs to discover what is currently drawing users to the competition.<br/><br/>With social media on the rise, there will always be the latest trends and features to draw in users. Instagram already has a brand users are familiar with, now it’s just a matter of redesigning the product to stay ahead of the curve.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Process</h3>
                    <h4>Survey Results</h4>
                    <p>I surveyed 122 middle school students at Basha Junior High School in Chandler, Arizona.<br/><br/>[Ages 13-14, 47% female, 53% male].<br/><br/>Students had many practical ideas such as categorizing options and using search functions to find content. Some innovative ideas were being able to search to find a photo by date or album and being able to place mobile orders straight through the app.</p>
                    <div class="contentBlock-flex">
                        <div>
                            <p>Which social media apps do you use most frequently?</p>
                            <img :src="require(`@/assets/instasurvey2.png`)" />
                        </div>
                        <div>
                            <p>Which features would enhance Instagram?</p>
                            <img :src="require(`@/assets/instasurvey1.png`)" />
                        </div>
                    </div>
                    <h4>Competitive Analysis</h4>
                    <p>TikTok and SnapChat are some of the leading competitors and Instagram wants to find out what these sites are offering that can help enhance their product.</p>
                     <div class="contentBlock-flex">
                        <div>
                            <h5>SnapChat</h5>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>SnapChat is the leading competitor with high user base, significant growth rate, and a robust mobile interface. The social media app doesn’t focus on how many ‘likes” or friends users have, making it easy and manageable.</span><br/><br/><span class="strengthWeaknessText">Weaknesses<br/>Even though SnapChat is the leading social media site for teens, it still has a few downsides. The app has an over dependence on advertising for revenue with limited features compared to its competitors while lacking diversification.</span></p>
                        </div>
                        <div>
                            <h5>Tik Tok</h5>
                            <p class="strengthWeaknessP"><span class="strengthWeaknessText">Strengths<br/>Tik Tok is a close second for teen’s choice in social media. It draws users by honing their creativity, while being rated high in popularity and entertainment.</span><br/><br/><span class="strengthWeaknessText">Weaknesses<br/>The app still has room for opportunity, the main ones being that it’s addictive and can become a time waster. There is a lack of user security and a misuse by some users, leading to a lack of trust in the product.</span></p>
                        </div>
                    </div>
                    <h4>User Personas</h4>
                    <p>To start developing perspective of who I would be advertising the product to, I needed to develop user personas with realistic wants and needs. I created two user personas, Addyson and Joey, who use social media for positive purposes.<br/><br/>Both users are active and strive for high achievements in both education and sports. They use social media for fun with their friends during their free time and have innovative ideas on how to enhance it to better connect with people and make it practical for achieving an end goal.</p>
                    <img :src="require(`@/assets/instapersona1.jpg`)" />
                    <img :src="require(`@/assets/instapersona2.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h4>User Stories</h4>
                    <p>To best be able to organize the data, I created objectives for new and returning users and divided them into low, medium, and high categories. Having clear goals of what users wanted helped narrow down the pain points of the user and the features that would fill those needs.</p>
                    <img :src="require(`@/assets/instauserstories.jpg`)" />
                    <h4>User Flows</h4>
                    <p>The user flows simplified the paths users would take to get to their end goals. From the My Profile section, users can choose to block user comments and find photographs by date or album. Users are able to use the search tool to find and rank categories. Then from the dashboard, users can also access applications like Starbucks and order straight from Instagram so they never have to leave the platform.  </p>
                    <img :src="require(`@/assets/instauserflow.jpg`)" />
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Framework</h3>
                    <h4>Low Fi Mockups</h4>
                    <p>Since I wasn’t changing the design of Instagram, I was able to use the current structure of the app like the header, footer and basic layout. My goal was to find ways to enhance the app with an updated user profile, searchable content, and mobile orders.<br/><br/>As I created the profile page, I wanted to stick with the same feel of the current site. Instagram tends to simplify its content and focus on visuals such as photos and videos as opposed to a lot of text. The wireframes I created laid out the content simply, and included the mobile orders in the feed.</p>
                    <img :src="require(`@/assets/insta-wireframes.jpg`)" />
                    <h4>Hi Fi Mockups</h4>
                    <p>The design of the My Profile page was inspired by MySpace where users could add their own personality and content to showcase on their own page. The profile can display favorite groups and play music to personalize it for each user.<br/><br/>The Find Photo page is found in the hamburger menu and can find a photo simply by entering in a date, album, or category. The design on the right allows the users to order their favorite content in a hierarchy format so their favorites pop up first.</p>
                    <img :src="require(`@/assets/insta-mockups.png`)" />
                    <h4>Prototype</h4>
                    <p>The mobile order pages are included on the feed, reminding users they can order their favorite food items without even leaving the Instagram platform. To go through the whole process the first time, the user will transition through seven different screens to sign up for rewards, select payment, and place their order. Once they have set up payment, the process will jump to the order screen and reduce by four steps.</p>
                    <img :src="require(`@/assets/insta-p.png`)" />
            </ContentBlock>
            <ContentBlock bgColor="#FFFFFF" class="project-text">
                    <h3>The Impact</h3>
                    <p>After developing the prototype of the upgraded application, I needed to try it out with the targeted age group to test it’s usability.<br/><br/>Users liked the ease of use when ordering from an app like Starbucks. They remarked it made them want to order the product more frequently which would be a huge incentive for company advertisements. One other user commented it could be connected to apps like Uber Eats to provide a quick delivery.<br/><br/>Many suggested maximizing the search features to be able to find, group, and save content, much like Pinterest. They also wanted to be able to create unique and original videos similar to the competitors to add their own creativity and personalization.</p>
            </ContentBlock>
            <ContentBlock bgColor="#EFEFEF" class="project-text">
                    <h3>The Reflection</h3>
                    <h4>Discovering a Solution</h4>
                    <p>After surveying middle school age adolescents to discover why they are leaving Instagram for other social media sites, it was apparent what features could be added to enhance the application.<br/><br/>Features that will win back the younger generation:</p>
                    <ul>
                        <li>enhancing My Profile to show creativity and personality</li>
                        <li>finding photos by date, album, or category</li>
                        <li>having the ability to categorize favorite content</li>
                        <li>adding a search feature to search for specific content</li>  
                        <li>placing an online order directly from Instagram’s feed</li>
                    </ul>
                    <h4>What I Learned</h4>
                     <div class="contentBlock-flex-ending">
                        <div>
                            <img :src="require(`@/assets/insta.png`)" />
                        </div>
                        <div>
                            <p>Once finishing the prototype and receiving user feedback, I realized that the middle school target audience had many functional and convenient ideas that benefit all age groups.<br/><br/>The feedback I received had added benefits of enhancing advertising, therefore increasing revenue for the site. Starbucks, Uber Eats, and countless others products can be purchased straight from the app.<br/><br/>Instagram already has a wide user base, now it’s just a matter of enticing the audience to come back and use the new features.<br/><br/>If Instagram wants to remain a leading competitor in the market, they will consistently need to enhance their platform as social media sites are frequently changing.</p>
                        </div>
                    </div>
            </ContentBlock>
        </div>
    </div>
</template>

<script>
    import ContentBlock from '../components/ContentBlock.vue'

    export default {
        name: 'Flyt',
        components: {
            ContentBlock
        }
    }
</script>

<style scoped>
    .intro{
        text-align: center;
        font-weight: 700;
        margin-top: 50px;
        padding: 0px 30px;
        box-sizing: border-box;
    }

     h1{
        font-size: 3em;
    }

    h2{
        font-size: 2em;
        margin-top: 10px;
    }

    h3, h5{
        color: #EF426E;
    }

    .content{
        margin: 100px 0px 30px 0px;
    }

    #logo{
        max-width: 500px;
    }

    .project-text{
        padding: 50px;
    }

    @media only screen and (max-width: 700px){
            h1{
                font-size: 2em;
            }

            h2{
                font-size: 1.3em;
                margin-top: 5px;
            }

            .project-text{
                padding: 30px;
            }
    }
</style>
