<template>
    <div class="card">
        <img :src="require(`@/assets/${image}`)" />
        <div class="text">
            <h2>{{ title }}</h2>
            <p>
                <slot></slot>
            </p>
            <router-link id="next" :to="link" :style="{'color':color }">Read the Case Study</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        image: String,
        title: String,
        link: String,
        color: String
    }
};
</script>

<style scoped>
    .card{
        display: flex;
        flex-direction: row;
        background-color: white;
        justify-content: center;
        align-items: center;
        max-width: 1300px;
        margin: 20px auto;
        padding: 30px;
        box-sizing: border-box;
    }
    .card img{
        flex: 1;
        padding: 30px;
        width: 100%;
    }
    .card .text{
        flex: 3;
        text-align: center;
    }
    h2{
        font-size: 2.5em;
    }
    p{
        font-size: 1em;
        color: black;
        margin-top: 20px;
    }
    #next{
        text-decoration: none;
        display: block;
        margin-top: 35px;
        font-size: 1.1em;
        font-weight: bold;
    }

    #next:hover{
        filter: brightness(0.6);
        transition: 0.4s;
    }

    @media only screen and (max-width: 900px){
            .card{
                display: flex;
                flex-direction: column;
            }

            h2{
                font-size: 1.5em;
            }

            p{
                font-size: 0.9em;
            }

            #next{
                font-size: 1em;
            }
    }
</style>